/* */
* {
  margin: 0;
  color: #2b234a;
}

.App {
  width: 100%;
  height: 100%;
}

/* 애니메이션 */
.fade-in {
  animation: fadedownin 3s;
}

.fade-out {
  animation: fadeout 1s;
}

@keyframes fadedownin {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.float {
  animation: floating 1.5s infinite alternate;
}

@keyframes floating {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 5px);
  }
}

.fade-inout {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 카드 뒤집기 */
.flip {
  width: 130px;
  height: 130px;
  perspective: 1100px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: 0.4s;
  transform-style: preserve-3d;
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front {
  background: red;
}

.back {
  background: royalblue;
  transform: rotateY(180deg);
}

.flip:hover .card {
  transform: rotateY(180deg);
}
/* Font */
@font-face {
  font-family: "NotoSans-Medium";
  src: url("./fonts/NotoSans-Medium.ttf");
}

@font-face {
  font-family: "NotoSans-Regular";
  src: url("./fonts/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "NotoSans-Bold";
  src: url("./fonts/NotoSans-Bold.ttf");
}

@font-face {
  font-family: "NotoSans-SemiBold";
  src: url("./fonts/NotoSans-SemiBold.ttf");
}

@font-face {
  font-family: "NotoSans-Light";
  src: url("./fonts/NotoSans-Light.ttf");
}
