/* Swiper css for custom pagination */
.swiper-pagination {
  bottom: 0;
  height: 30px;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 5px;
}

.swiper-pagination-bullet {
  display: block;
  width: 2px;
  height: 2px;
  background: rgba(86, 103, 234, 0.2);
  border-radius: 50px;
  opacity: 1;
  margin: 0 10px;
  padding: 5px;
}

.swiper-pagination-bullet-active {
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50px;
  background: #5667ea;
  border: 1px solid transparent;
}
